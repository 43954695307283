import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import PersonalInformation from '../components/user/profile/personal-info/PersonalInformation'
import ShippingInformation from '../components/user/profile/shipping-info/ShippingInformation'
import FinancialInformation from '../components/user/profile/financial-info/FinancialInformation'
import BusinesConfigForm from '../components/user/profile/business-config/BusinesConfigForm'
import ProfileSumarry from '../components/user/profile/ProfileSummary'
import { getIsBusiness } from '../store/login/reducers'
import DropOffOptionsForm from '../components/user/profile/drop-off-options/DropOffOptionsForm'

const MyProfileScreen = (props) => {

    const isBusiness = useSelector((state) => getIsBusiness(state))

    useEffect(() => {
        const hash = window.location.hash
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''))
            if (element) {
                const yOffset = -140 // Ajusta este valor al tamaño de tu navbar o al espacio que necesites
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
                window.scrollTo({ top: y, behavior: 'smooth' })
            }

            //  const element = document.querySelector(hash)
            //  if (element) {
            //      element.scrollIntoView({ behavior: 'smooth' })
            //  }
        }
    }, [])


    return (
        <div>
            <ProfileSumarry />
            <PersonalInformation />
            <a id='shipping-information'></a>
            <ShippingInformation />
            <a id='financial-information'></a>
            <FinancialInformation />
            {isBusiness && <BusinesConfigForm />}
            {!isBusiness && <DropOffOptionsForm />}
        </div>
    )
}

export default withRouter(injectIntl(connect()(MyProfileScreen)))

