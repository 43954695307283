import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { defineMessage, defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import FinancialInformationContainer from './FinancialInformationContainer'
import Alerts from '../../../../components/alerts/Alerts'

defineMessages(
    {
        FINANCIAL_INFORMATION_INFO_MESSAGE: {
            id: 'FINANCIAL_INFORMATION_INFO_MESSAGE',
            description: 'FINANCIAL_INFORMATION_INFO_MESSAGE',
            defaultMessage: 'At Cards & Collections, we ask for your bank account number to verify your identity as a seller. This helps us protect buyers by ensuring that all sellers on our platform are legitimate. Your information is only used for this purpose and to securely deposit your earnings from sales',
        }
    }
)

const FinancialInformation = () => {

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <Alerts
                        status="info"
                        alert="FINANCIAL_INFORMATION_INFO_MESSAGE"
                    ></Alerts>
                    <FinancialInformationContainer />
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(FinancialInformation)))
