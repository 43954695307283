export const initState = {
    loading: false,
    dropOffAvailableStores: [],
    errors: null
}

const shippingMethods = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_DROP_OFF_AVAILABLE_STORES_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case 'FETCH_DROP_OFF_AVAILABLE_STORES_SUCCESS':
            return {
                ...state,
                dropOffAvailableStores: action.dropOffAvailableStores,
                loading: false,
            }
        case 'FETCH_DROP_OFF_AVAILABLE_STORES_FAILURE':
            return {
                ...state,
                loading: false,
            }
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export const getAllDropOffAvailableStores = (state) => {
    return state.shippingMethods.dropOffAvailableStores
}

export const isLoadingAllDropOffAvailableStores = (state) => {
    return state.shippingMethods.loading
}

export default shippingMethods
