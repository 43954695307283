import { RenderProductSingleProperties } from './renderProductSingleInfo/RenderProductSingleProperties';
import { RenderProductSingleActions } from './renderProductSingleInfo/RenderProductSingleActions';
import React from 'react'
import Translation from '../global/Translation'
import DisplayPrice from '../product/DisplayPrice'
import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'
import noImage from '../../assets/img/advanced-search/advanced-search.jpg'

class SaleItemSimplifiedResult extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showFullCard: false,
            showReverseCard: false,
            flipCard: false,
            showOtherFaceImg: false,
        }
    }

    render() {
        const { product, item,intl,isUserItemEqualToCurrentLogged,isLogged,userName,isVerified,addingToCart,isLoading,bankData,onShowUpLogin,addToCart,deleteSaleItem,showActions = true } = this.props
        const imageUrl = this.state.showReverseCard ? `${product.img}&face=back` : this.state.showOtherFaceImg ? product.otherFaceImg : product.img ? product.img : noImage

        return (
            <div key={product.id} className="result card simplified-result" style={{ display: 'flex', flexDirection: 'row' }}>
                <React.Fragment>
                    <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: product.id }}>
                        <div
                            className="card-image"
                            data-tip
                            data-for={'min-' + product.id}
                            style={{
                                backgroundImage: `url("${imageUrl}")`,
                                // width: '100%',
                                // height: '100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: product.productCategory === 'mtg_sealed' ? '50%' : 'contain',
                                backgroundPosition: product.productCategory === 'mtg_sealed' ? 'center center' : 'center left',
                            }}
                        ></div>
                    </LocalizedLink>
                    <div className="card-body">
                        <div>
                            <h5 className="card-title">
                                <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: product.id }}>
                                    {product.name}
                                </LocalizedLink>
                            </h5>
                            {/*Espansion (DST)*/}
                            <span className="card-text card-expansion" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {product.keyruneCode && (
                                    <img
                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                        src={`https://svgs.scryfall.io/sets/${product.keyruneCode.toLowerCase()}.svg`}
                                        onError={(event) => {
                                            event.target.onerror = ''
                                            event.target.src = 'https://svgs.scryfall.io/sets/planeswalker.svg'
                                            return true
                                        }}
                                    />
                                )}

                                {product.setName}
                            </span>
                        </div>
                        <div className="">
                            <div className="properties" style={{ display: 'flex', flexDirection: 'row', padding: '0 0 8px 0' }}>
                                <RenderProductSingleProperties original={item} showExpansion={false} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                            <ul className="" style={{ listStyle: 'none', paddingLeft: 0 }}>
                                <li className="">
                                    <label style={{ color: '#71717A' }}>
                                        <Translation id="price" defaultMessage="Price" />
                                    </label>
                                    <br />
                                    <span style={{ fontSize: '14px !important' }}>
                                        <DisplayPrice price={item.price}></DisplayPrice>
                                    </span>
                                    <br />
                                </li>
                            </ul>
                            <ul className="" style={{ listStyle: 'none', paddingLeft: 0 }}>
                                <li className="">
                                    <label style={{ color: '#71717A' }}>
                                        <Translation id="qty" defaultMessage="Quantity" />
                                    </label>
                                    <br />
                                    {item.qty}
                                    <br />
                                </li>
                            </ul>
                        </div>
                        <div className="" style={{ display: 'flex', flexDirection: 'column' }}>
                            {showActions && (
                                <div className="actions">
                                    <RenderProductSingleActions
                                        original={item}
                                        intl={intl}
                                        viewEdit={'edit-item-sale'}
                                        currentUserIsSeller={isUserItemEqualToCurrentLogged}
                                        isLogged={isLogged}
                                        userName={userName}
                                        isVerified={isVerified}
                                        addingToCart={addingToCart}
                                        isLoading={isLoading}
                                        bankData={bankData}
                                        onShowUpLogin={onShowUpLogin}
                                        addToCart={addToCart}
                                        deleteItem={deleteSaleItem}
                                    />
                                </div>
                            )}
                            {product.layout === 'transform' && (
                                <div
                                    className="text-right"
                                    style={{
                                        display: 'flex',
                                        background: 'white',
                                        position: 'absolute',
                                        bottom: '8px',
                                        right: '8px',
                                        cursor: 'pointer',
                                        borderRadius: '16px',
                                    }}
                                >
                                    <span
                                        className="material-icons-outlined"
                                        style={{ fontSize: '32px', cursor: 'pointer' }}
                                        onClick={() => this.setState({ showReverseCard: !this.state.showReverseCard })}
                                    >
                                        autorenew
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            </div>
        )
    }
}

export default injectIntl(SaleItemSimplifiedResult)
