import { connect } from 'react-redux'
import CartLine from './CartLine'
import {
    removeCartLine,
    changeCartLineQty,
    changeCartLineSegmentation,
    changeCartLineComment,
    saveCartLineComment
} from '../../store/cart/actions'
// import { getQtyProductsCart } from '../../store/cart/reducers'
import { getLanguage } from '../../store/ui/reducers'
import { getCartType } from '../../store/cart/reducers'

const mapStateToProps = state => {
    return {
        // qtyProductCart: getQtyProductsCart(state),
        lang: getLanguage(state),
        cartType: getCartType(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDelete: lineId => {
            dispatch(removeCartLine(lineId))
        },
        changeCartLineQty: (lineId, qty) => {
            dispatch(changeCartLineQty(lineId, qty))
        },
        // saveCartLineComment: (lineId, comment) => dispatch(saveCartLineComment(lineId, comment)),

        // changeCartLineComment: (lineId, comment) => dispatch(changeCartLineComment(lineId, comment))
    }
}

const CartLineContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartLine)

export default CartLineContainer
