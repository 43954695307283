import React from 'react'
import Translation from '../global/Translation'
import ReactTooltip from 'react-tooltip'

export const OrderDropOffFile = ({ orderId, fileName, userToken }) => {

    if (!fileName) {
        return null
    }

    const multimediaUrl = `${process.env.REACT_APP_API_HOST}/api/order/${orderId}/drop-off-file?authToken=${userToken}`

    return (
        <div>
            <ReactTooltip backgroundColor={'black'} effect='solid' id='dropOffFile'>
                <img alt='' style={{ width: 150 }} src={multimediaUrl} />
            </ReactTooltip>

            <a href={multimediaUrl} target='_blank' data-tip data-for={'dropOffFile'}>
                <Translation id='drop_off_file' />:{' '}
                <i className='far fa-image fa-lg cursor-pointer' ></i>
            </a>
        </div>
    )
}
