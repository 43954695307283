import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDropOffOptions } from '../../../../store/login/reducers'
import { updateUserDropOffOptions } from '../../../../store/login/actions'
import { useFormik } from 'formik'
import Translation from '../../../global/Translation'
import Button from '../../../global/Button'
import {
    getAllDropOffAvailableStores,
    isLoadingAllDropOffAvailableStores
} from '../../../../store/shipping-methods/reducers'
import Select from 'react-select'
import { fetchDropOffAvailableStores } from '../../../../store/shipping-methods/actions'
import Spinner from '../../../global/Spinner'
import { selectMultiOptionExtractValue } from '../../../shared/form/select-input'

const Form = ({ userDropOffOptions, dropOffAvailableStoresOptions }) => {
    const dispatch = useDispatch()

    const parseToFormValue = (value) => {
        return {
            allowInStoreDropOffShippingMethod: value.allowInStoreDropOffShippingMethod ?? false,
            dropOffStores: value.filter(option => (userDropOffOptions.dropOffStores ?? []).includes(option.value))
        }
    }

    const initialValues = {
        allowInStoreDropOffShippingMethod: userDropOffOptions.allowInStoreDropOffShippingMethod ?? false,
        dropOffStores: dropOffAvailableStoresOptions.filter(option => (userDropOffOptions.dropOffStores ?? []).includes(option.value))
    }

    const onSubmit = (values, action) => {
        dispatch(updateUserDropOffOptions(values.allowInStoreDropOffShippingMethod, selectMultiOptionExtractValue(values.dropOffStores)))
            .then(() => {
                    action.resetForm({
                        values: {
                            allowInStoreDropOffShippingMethod: values.allowInStoreDropOffShippingMethod,
                            dropOffStores: values.dropOffStores
                        }
                    })
                }
            )
            .catch((err) => {
            })
    }

    const handleClickSubmit = () => {
        formik.submitForm()
    }

    const formik = useFormik({
        initialValues,
        onSubmit
    })

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='mb-3'>
                    <div className='col-12'>
                        <form className='form'>
                            <div className='row'>
                                <h3>
                                    <Translation id='dropOffConfig' defaultMessage='Drop off options' />
                                </h3>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-check'>
                                            <label htmlFor='allowInStoreDropOffShippingMethod'
                                                   className='form-check-label'>
                                                <Translation id='ALLOW_IN_STORE_DROP_OFF_SHIPPING_METHOD'
                                                             defaultMessage='Allow my products to be droped off in store' />
                                            </label>
                                            <input
                                                type='checkbox'
                                                id='allowInStoreDropOffShippingMethod'
                                                name='allowInStoreDropOffShippingMethod'
                                                className='form-check-input'
                                                value={formik.values.allowInStoreDropOffShippingMethod}
                                                checked={formik.values.allowInStoreDropOffShippingMethod}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>

                                        <div className='form-check'>
                                            <label htmlFor='dropOffStores'
                                                   className='form-check-label'>
                                                <Translation id='DROP_OFF_STORES'
                                                             defaultMessage='Drop off stores' />
                                            </label>

                                            <Select
                                                id='dropOffStores'
                                                name='dropOffStores'
                                                options={dropOffAvailableStoresOptions}
                                                isDisabled={false === formik.values.allowInStoreDropOffShippingMethod}
                                                isMulti
                                                value={formik.values.dropOffStores}
                                                onChange={option => formik.setFieldValue('dropOffStores', option)}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>

                                        <div className='mb-3'>
                                            <Button type='submit' disabled={!formik.dirty || !formik.isValid}
                                                    onClick={handleClickSubmit}>
                                                <Translation id='update' defaultMessage='Update' />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


const DropOffOptionsContainer = () => {
    const dispatch = useDispatch()
    const dropOffOptions = useSelector((state) => getDropOffOptions(state))
    const dropOffAvailableStoresOptions = useSelector((state) => {
        return getAllDropOffAvailableStores(state).map(({ name, id }) => ({ label: name, value: id }))
    })
    const isLoading = useSelector(state => isLoadingAllDropOffAvailableStores(state))

    useEffect(() => {
        dispatch(fetchDropOffAvailableStores())
    }, [])

    if (isLoading) {
        return <Spinner />
    }

    return (
        <Form
            userDropOffOptions={dropOffOptions}
            dropOffAvailableStoresOptions={dropOffAvailableStoresOptions}
        />
    )
}

const DropOffOptionsForm = DropOffOptionsContainer

export default DropOffOptionsForm
