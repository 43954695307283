import { payMyPendingCredit } from '../../store/cart/actions'
import { getCredit, getIsBusiness, getToken, getUserId, getUserName } from '../../store/login/reducers'
import { connect } from 'react-redux'
import {
    getOrderActions,
    getOrderFormErrors,
    getOrderHeadById,
    getOrderIsSending,
    getOrderLinesById
} from '../../store/orders/reducers'
import { changeOrderStatus, fetchOrderById, storeOrderDropOffFile } from '../../store/orders/actions'
import Order from './Order'

const mapStateToProps = (state, { match }) => {
    return {
        id: match.params.id,
        orderLines: getOrderLinesById(state, match.params.id),
        order: getOrderHeadById(state, match.params.id),
        orderActions: getOrderActions(state, match.params.id),
        userId: getUserId(state),
        userName: getUserName(state),
        credit: getCredit(state),
        isBusiness: getIsBusiness(state),
        userToken: getToken(state),
        isSending: getOrderIsSending(state),
        formErrors: getOrderFormErrors(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrderById: (id) => {
            return dispatch(fetchOrderById(id))
        },
        changeOrderStatus: (id, action, payload = null) => {
            return dispatch(changeOrderStatus(id, action, payload))
        },
        payMyPendingCredit: (url) => {
            dispatch(payMyPendingCredit(url))
        },
        storeOrderDropOffFile: (id, dropOffFile) => {
            return dispatch(storeOrderDropOffFile(id, dropOffFile))
        }
    }
}

const OrderContainer = connect(mapStateToProps, mapDispatchToProps)(Order)

export default OrderContainer
