export const selectOptionExtractValue = (option) => {
    return option?.value ?? null;
}

export const selectMultiOptionExtractValue = (options) => {
    if (!options || !Array.isArray(options)) {
        return [];
    }

    return options.map((o) => o.value);
}