import  ReactTooltip  from 'react-tooltip';
import LocalizedLink from '../../global/LocalizedLink'
import Translation from '../../global/Translation'
import React from 'react'
import { RenderProductSingleProperties } from '../../product/renderProductSingleInfo/RenderProductSingleProperties'
import DisplayPrice from '../../product/DisplayPrice'
import { RenderProductSingleActions } from '../../product/renderProductSingleInfo/RenderProductSingleActions'
import noImage from '../../../assets/img/advanced-search/advanced-search.jpg'

export const RenderSaleItemMobile = ({
                                         item,
                                         intl,
                                         isLogged,
                                         userName,
                                         isVerified,
                                         bankData,
                                         addingToCart,
                                         isLoading,
                                         updateQty,
                                         onShowUpLogin,
                                         isUserItemEqualToCurrentLogged,
                                         addToCart,
                                         deleteSaleItem,
                                         showActions= true
                                     }) => {

    return (
        <div key={item.id} className="card table-row">
            <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="img">
                    <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: item.product.id }}>
                        <img
                            src={item.product.img}
                            className="card-img-top"
                            data-tip
                            data-for={'min-' + item.product.id}
                            alt="..."
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = noImage
                                return true
                            }}
                        />
                        <ReactTooltip backgroundColor={'white'} id={'min-' + item.product.id} effect="solid">
                            <img alt="product" style={{ width: '256px' }} src={item.product.img}></img>
                        </ReactTooltip>
                    </LocalizedLink>
                </div>
                <div>
                    <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: item.product.id }}>
                        <h5 className="card-title">{item.product.name}</h5>
                    </LocalizedLink>
                </div>
            </div>

                <div className="properties" style={{ display: 'flex', flexDirection: 'row' }}>
                    <RenderProductSingleProperties original={item} showExpansion={true} />
                </div>

            <div className="card-footer" style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                    <span>
                        <Translation id="qty" defaultMessage="Quantity" />
                    </span>
                    <strong>{item.qty}</strong>
                </div>
                <div>
                    <span>
                        <Translation id="price" defaultMessage="Price" />
                    </span>
                    <DisplayPrice price={item.price}></DisplayPrice>
                </div>
                {showActions && (
                    <div className="actions">
                        <RenderProductSingleActions
                            original={item}
                            intl={intl}
                            viewEdit={'edit-item-sale'}
                            currentUserIsSeller={isUserItemEqualToCurrentLogged}
                            isLogged={isLogged}
                            userName={userName}
                            isVerified={isVerified}
                            addingToCart={addingToCart}
                            isLoading={isLoading}
                            bankData={bankData}
                            onShowUpLogin={onShowUpLogin}
                            addToCart={addToCart}
                            deleteItem={deleteSaleItem}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
