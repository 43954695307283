import Translation from '../../global/Translation'
import { Hits, RefinementListFilter, SearchBox, SearchkitProvider } from 'searchkit'
import Results from '../../../Results'
import noImage from '../../../assets/img/advanced-search/advanced-search.jpg'
import React from 'react'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'

const selectMtgSetComponent = (intl) => (props) => {
    return (
        <Select
            className=''
            isClearable={true}
            options={props.items.map((item) => ({
                label: item.key,
                value: item.key
            }))}
            value={props.selectedItems}
            onChange={(selectedOption) => {
                props.setItems(selectedOption)
            }}
            placeholder={
                intl.formatMessage({
                    id: 'select_the_game_set'
                }) + '...'
            }
        />
    )
}

const selectMtgProductCategory = (productsCategoriesOptions, onlySingle, intl) => (props) => {
    const defaultValue = productsCategoriesOptions[0]
    if (Array.isArray(props.selectedItems) && !props.selectedItems.length || !props.selectedItems) {
        props.setItems(defaultValue)
    }
    if (onlySingle) {
        return null
    }
    return <Select
        className=''
        isClearable={true}
        options={productsCategoriesOptions}
        value={props.selectedItems}
        onChange={(selectedOption) => {
            props.setItems(selectedOption)
        }}
        placeholder={intl.formatMessage({
            id: 'mtg_category_filter_label'
        }) + '...'}
    />
}

const CardSelect = ({formMode, productsCategoriesOptions, selectedImg, productSelectedId, onlySingle, intl, sk, handleSelectProduct}) => {

    const hitsComponent = (hit) => {
        let product = hit.result._source
        if (!product) return null
        return (
            <div
                className="col-12 result-item-list"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                    background: product.id == productSelectedId ? '#ffc466' : 'white',
                    color: product.id == productSelectedId ? '#101823' : 'default',
                    borderRadius: '0',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    border: '1px solid #999',
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px',
                }}
                onClick={() => handleSelectProduct(product)}
            >
                <div className="image card-img-left">
                    <ReactTooltip id={'min-' + product.id} effect="solid">
                        <img
                            alt="product"
                            style={{ width: '256px' }}
                            src={product.img}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = noImage
                                return true
                            }}
                        ></img>
                    </ReactTooltip>
                    {/* <LocalizedLink routeId="ROUTE_PRODUCT" params={{ id: product.id }}> */}
                    <img
                        alt="product"
                        style={{ width: '40px' }}
                        src={product.img}
                        data-tip
                        data-for={'min-' + product.id}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null
                            currentTarget.src = noImage
                            return true
                        }}
                    ></img>
                    {/* </LocalizedLink> */}
                </div>
                <div className="" style={{ borderRadius: '0px', marginLeft: '16px' }}>
                    <ReactTooltip id={'min-set-' + product.id} effect="solid">
                        {product.setName}
                    </ReactTooltip>
                    {product.keyruneCode && (
                        <img
                            style={{ width: '20px', marginRight: '16px' }}
                            src={`https://svgs.scryfall.io/sets/${product.keyruneCode.toLowerCase()}.svg`}
                            data-tip
                            data-for={'min-set-' + product.id}
                            onError={(event) => {
                                event.target.onerror = ''
                                event.target.src = 'https://svgs.scryfall.io/sets/planeswalker.svg'
                                return true
                            }}
                        />
                    )}
                    <span className="card-title">{product.name}</span>{' '}
                </div>
            </div>
        )
    }
    const hits = <Hits hitsPerPage={8} itemComponent={hitsComponent} />


    return (
        <React.Fragment>
            <div className={`col-12 col-lg-4 ${formMode === 'edit' ? 'd-none' : ''}`}>
                <label htmlFor="card">
                    <h4>
                        <Translation id="add_item_step_1" defaultMessage="1. Select your item" />
                    </h4>
                </label>

                <SearchkitProvider searchkit={sk}>
                    {!onlySingle &&<div className="row" style={{marginBottom:'8px'}}>
                        <div className="col-12">
                            <RefinementListFilter
                                field="productCategory.raw"
                                title={null}
                                id="productCategory"
                                operator="OR"
                                showCount={false}
                                listComponent={selectMtgProductCategory(productsCategoriesOptions, onlySingle, intl)}
                            />
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="search-box-container">
                                <SearchBox
                                    autofocus={true}
                                    searchThrottleTime={1000}
                                    searchOnChange={true}
                                    queryFields={['name', 'setCode', 'setName']}
                                    prefixQueryFields={['name', 'setCode', 'setName']}
                                    queryOptions={{ default_operator: 'AND' }}
                                    placeholder={'Search'}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <RefinementListFilter id="cards" field="setNameForFilter.raw" title={''} listComponent={selectMtgSetComponent(intl)} size={999} />
                        </div>
                        <br />
                        <br />
                        <Results hits={hits} />
                    </div>
                </SearchkitProvider>
            </div>
            <div className="col-12 col-lg-3">
                <img
                    style={{ width: '100%' }}
                    src={selectedImg ? selectedImg : noImage}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = noImage
                        return true
                    }}
                />
            </div>
        </React.Fragment>
    )
}

export default CardSelect;