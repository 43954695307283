import { Votes } from '../../../screens/PublicProfileScreen';
import Translation from '../../global/Translation'
import { PublicProfileLink } from '../../global/PublicProfileLink'
import DisplayPrice from '../DisplayPrice'
import React from 'react'
import { RenderProductSingleProperties } from '../renderProductSingleInfo/RenderProductSingleProperties'
import { RenderProductSingleActions } from '../renderProductSingleInfo/RenderProductSingleActions'

export const ProductBuyItem = ({ original,
                                    intl,
                                    isLogged,
                                    userName,
                                    isVerified,
                                    addingToCart,
                                    isLoading,
                                    bankData,
                                    onShowUpLogin,
                                    addToCart,
                                    deleteMyItem,
                                    hideActions= false}) => {
    return (
        <div key={original.id} className="card table-row">
            <div className="card-header" style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                    <span>
                        <Translation id="seller" defaultMessage="Seller" />
                        {original.vat_prefix && (
                            <div className="badge badge-professional" style={{ background: '#ff6669' }}>
                                PRO
                            </div>
                        )}
                    </span>

                    <strong>
                        <PublicProfileLink
                            userNameSlug={original.sellerNameSlug}
                            label={userName === original.sellerName ? <strong>{original.sellerName}</strong> : original.sellerName}
                            // transactions={original.finished_orders_count}
                            // votes={original.vote_seller_general}
                        />
                    </strong>
                </div>
                <div>
                    <span>
                        <Translation id="rating" defaultMessage="Rating" />
                    </span>
                    {original.vote_seller_general && <Votes votes={original.vote_seller_general} />}
                </div>
                <div>
                    <span>
                        <Translation id="sales" defaultMessage="Sales" />
                    </span>
                    <strong>{!!original.finished_orders_count && <span>{original.finished_orders_count}</span>}</strong>
                </div>
            </div>
                <div className="properties" style={{ display: 'flex', flexDirection: 'row' }}>
                    <RenderProductSingleProperties original={original} />
                </div>
            <div className="card-footer" style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                    <span>
                        <Translation id="qty" defaultMessage="Quantity" />
                    </span>
                    <strong>{original.qty}</strong>
                </div>
                <div>
                    <span>
                        <Translation id="price" defaultMessage="Price" />
                    </span>
                    <strong>
                        <DisplayPrice price={original.price}></DisplayPrice>
                    </strong>
                </div>
                {!hideActions && (
                    <div className="actions">
                        <RenderProductSingleActions
                            original={original}
                            intl={intl}
                            viewEdit={'edit-buy-sale'}
                            currentUserIsSeller={userName == original.sellerName}
                            isLogged={isLogged}
                            userName={userName}
                            isVerified={isVerified}
                            addingToCart={addingToCart}
                            isLoading={isLoading}
                            bankData={bankData}
                            onShowUpLogin={onShowUpLogin}
                            addToCart={addToCart}
                            deleteItem={deleteMyItem}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
